import "./App.css";
import "./css/main.css";
import "./css/Spielkarte.css";
import { useEffect } from "react";
import { NHIE } from "./Seiten/NHIE";
import { BrowserRouter as Router } from "react-router-dom";
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";

export default function MyApp() {
  // Function to initialize non-essential cookies like analytics
  const enableAnalytics = () => {
    console.log("Analytics enabled");

    // Dynamically load Google Analytics script
    const script = document.createElement("script");
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-XG59GJMV97";
    script.async = true;
    document.head.appendChild(script);

    // Initialize Google Analytics
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag("js", new Date());
    gtag("config", "G-XG59GJMV97");
  };

  // Function to disable non-essential cookies
  const disableNonEssentialCookies = () => {
    console.log("Non-essential cookies disabled");
    // Example: Disable Google Analytics or other tracking services
    // Example: window['ga-disable-UA-XXXXXX-Y'] = true;
    // Clear cookies if needed
    document.cookie.split(";").forEach((cookie) => {
      const name = cookie.split("=")[0].trim();
      if (["trackingCookie1", "trackingCookie2"].includes(name)) {
        document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;`;
      }
    });
  };

  // Check consent value on page load
  useEffect(() => {
    const consent = getCookieConsentValue();
    if (consent === "true") {
      enableAnalytics(); // Enable analytics if user previously accepted
    }
  }, []);

  return (
    <Router>
      <div className="body">
        <NHIE />
        <CookieConsent
          location="bottom"
          buttonText="Akzeptieren"
          declineButtonText="Ablehnen"
          enableDeclineButton
          style={{
            border: "0.5px solid",
            color: "#000000",
            background: "#ffffff",
          }}
          buttonStyle={{
            color: "#000000",
            background: "#aa87ab",
          }}
          declineButtonStyle={{
            color: "#000000",
            background: "#ffffff",
          }}
          onAccept={() => {
            console.log("Cookies accepted");
            enableAnalytics(); // Enable non-essential cookies
          }}
          onDecline={() => {
            console.log("Cookies declined");
            disableNonEssentialCookies(); // Disable non-essential cookies
          }}
        >
          "Diese Website verwendet Cookies, um Ihr Nutzererlebnis zu verbessern.
          Durch Klicken auf 'Akzeptieren' stimmen Sie der Verwendung von nicht
          essenziellen Cookies zu. Wenn Sie auf 'Ablehnen' klicken, werden nur
          essenzielle Cookies verwendet, die für den Betrieb der Website
          erforderlich sind.
        </CookieConsent>
      </div>
    </Router>
  );
}
