export function Fragenpeinlich() {
  const texts = [
    "einen Freund im Stich gelassen",
    "das Handy von jemand anders durchsucht",
    "einen Fake-Account gehabt",
    "„Ich liebe dich“ gesagt, aber es nicht so gemeint",
    "alleine getrunken",
    "etwas geklaut",
    "gewünscht, dass mein Penis/meine Brüste größer wären",
    "jemand Fremdes auf den Hintern gehauen",
    "jemanden gemobbt",
    "mit meinen Eltern über Verhütung geredet",
    "einen peinlichen Korb bekommen",
    "etwas peinliches bei anderen Leuten gefunden und es nicht angesprochen",
    "als Vorbereitung auf ein Date geübt zu küssen",
    "bei einem Telefon schon mal so getan als sei die Verbindung abgebrochen",
    "ein Gerücht über jemanden in die Welt gesetzt",
  ];

  return { texts };
}
