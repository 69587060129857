import React, { useState, useEffect } from "react";
import "../css/Spielkarte.css";

export function SpielkarteDeeptalk() {
  const [randomText, setRandomText] = useState("");
  const [remainingTexts, setRemainingTexts] = useState([]);

  useEffect(() => {
    document.title = "Deeptalk - Spielkarte";

    // Lade die Texte aus sessionStorage
    const storedTexts = JSON.parse(sessionStorage.getItem("texts"));
    if (storedTexts && storedTexts.length > 0) {
      setRemainingTexts(storedTexts);
      handleRandomize(storedTexts); // Rufe die Funktion auf, um einen zufälligen Text auszuwählen
    }
  }, []);

  const handleRandomize = (texts = remainingTexts) => {
    if (texts.length === 0) {
      setRandomText("");
      return;
    }

    const randomTextIndex = Math.floor(Math.random() * texts.length);
    setRandomText(texts[randomTextIndex]);
    const newRemainingTexts = texts.filter(
      (_, index) => index !== randomTextIndex
    );
    setRemainingTexts(newRemainingTexts);
    sessionStorage.setItem("texts", JSON.stringify(newRemainingTexts));
  };

  return (
    <div className="container">
      <div className="card">
        <p id="kartentitel">Deeptalk:</p>
        <p id="kartentext">
          {randomText ||
            "Leider waren das alle Fragen. Um unsere Datenbank zu erweitern schreib uns gerne weitere unter: ich-hab-noch-nie.de@outlook.de"}
        </p>
        <button className="card-button" onClick={() => handleRandomize()}>
          Next
        </button>
      </div>
    </div>
  );
}
