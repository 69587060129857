import React from "react";
import facebookicon from "../images/Facebook_Logo_Primary.avif";

export function Stripes() {
  return (
    <div className="fixedstripes">
      {/* <div className="socials">
        <a
          href="https://www.facebook.com/people/Ich-hab-noch-nie/61561774679974/"
          title="Link zur Facebook Seite"
        >
          <img
            className="socialimg"
            src={facebookicon}
            alt="link zur Facebook Seite"
          />
        </a>
      </div> */}
      <div className="stripes" style={{ backgroundColor: "#F23041" }}></div>
      <div className="stripes" style={{ backgroundColor: "#617322" }}></div>
      <div className="stripes" style={{ backgroundColor: "#D97014" }}></div>
      <div className="stripes" style={{ backgroundColor: "#F23E16" }}></div>
    </div>
  );
}
