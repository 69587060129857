import * as React from "react";

export function Dropdown() {
  return (
    <div className="dropdown boxhead">
      <ul className="flex cleanlist">
        <li>
          {" "}
          <a className="dropdowna" href="https://ich-hab-noch-nie.de/">
            Home
          </a>
        </li>
        <li>
          <a
            className="dropdowna"
            href="https://ich-hab-noch-nie.de/Trinkspiele/"
          >
            Trinkspiele
          </a>
        </li>
        {/* <a className="dropdowna" href="https://ich-hab-noch-nie.de/Deeptalk/">
          Deeptalk
        </a> */}
        {/* <a
          className="dropdowna"
          href="https://ich-hab-noch-nie.de/Diskussionsfragen/"
        >
          Brainteaser
        </a> */}
      </ul>
    </div>
  );
}
