import React from "react";
import { Switch } from "./Switch";

export function Fragenfilter({ cn, selectedQuestions, onCheckboxChange }) {
  return (
    <div className={cn}>
      <Switch
        title="Fragen peinlich"
        name="fragenpeinlich"
        selectedQuestions={selectedQuestions}
        onCheckboxChange={onCheckboxChange}
      />
      <Switch
        title="Fragen 18+"
        name="fragen18"
        selectedQuestions={selectedQuestions}
        onCheckboxChange={onCheckboxChange}
      />
      <Switch
        title="Fragen lustig"
        name="fragenlustig"
        selectedQuestions={selectedQuestions}
        onCheckboxChange={onCheckboxChange}
      />
    </div>
  );
}
