import React from "react";
import { MyButton } from "./MyButton";

export function Footer({ onHome, onImp, onDate }) {
  return (
    <nav>
      <div className="footerbox">
        <div className="footerstripes">
          <MyButton onClick={onImp} value="Impressum" cn="footerbutton" />
        </div>
        <div className="footerstripes">
          <MyButton onClick={onDate} value="Datenschutz" cn="footerbutton" />
        </div>
      </div>
    </nav>
  );
}
