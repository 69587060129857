import React, { useState, useEffect } from "react";
import { Stripes } from "./Stripes";
import { MyHeading } from "./MyHeading";
import { MyButton } from "./MyButton";
import { Routes, Route, useNavigate } from "react-router-dom";
import testimonial from "../Testimonial.avif";
import ResponsiveImage from "./IHNNAstronautin";
import { Fragenfilter } from "./Fragenfilter";

const FixedSizeComponent = ({
  onNavigate,
  selectedQuestions,
  onCheckboxChange,
}) => {
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 1100);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 1100);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div className="schwarzebox">
        <MyHeading heading="Ich hab noch nie Fragen - einfach stellen lassen." />
        <br />
        <div className="grünebox">
          <div className="gocard">
            <Fragenfilter
              cn="fragenfilter"
              selectedQuestions={selectedQuestions}
              onCheckboxChange={onCheckboxChange}
            />
            <MyButton
              cn="fixscreen"
              onClick={onNavigate}
              value="Frage stellen"
              version="primary"
            />
            <br />
            <br />
            <MyButton
              cn="fixscreen"
              value="Feedback geben"
              version="secondary"
              onClick={() =>
                window.open("https://forms.gle/AURofmVUHruAzDVz8", "_blank")
              }
            ></MyButton>
          </div>
          {/* {isLargeScreen && (
            <ResponsiveImage
              fetch-priority="high"
              alt="Ich hab noch nie Astronautin"
              className="NHIE"
            />
          )} */}
        </div>
      </div>
      <Stripes />
    </>
  );
};

export default FixedSizeComponent;
