import React from "react";
import { MyButton } from "./MyButton";
import { Dropdown } from "./Dropdown";

export function Header({ onHome, onImp, onDate, onTrink, onDeep, onDisk }) {
  return (
    <nav>
      <div className="headerbox">
        <Dropdown
          onImp={onImp}
          onHome={onHome}
          onDate={onDate}
          onTrink={onTrink}
          onDeep={onDeep}
          onDisk={onDisk}
        />
        <div className="headerstripes"></div>
      </div>
    </nav>
  );
}
