import React, { useState, useEffect } from "react";
import { Stripes } from "./Stripes";
import { MyHeading } from "./MyHeading";
import { MyButton } from "./MyButton";
import { Routes, Route, useNavigate } from "react-router-dom";
import testimonial from "../Testimonial.avif";
import ResponsiveImage from "./IHNNAstronautin";
import { Fragenfilter } from "./Fragenfilter";

const FixedSizeComponentDisskussion = ({
  onNavigate,
  selectedQuestions,
  onCheckboxChange,
}) => {
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 1100);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 1100);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div className="schwarzebox">
        <MyHeading heading="Fragen gestellt bekommen zum disskutieren" />
        <div className="grüneboxd">
          <div className="gocard">
            <MyButton
              cn="fixscreen"
              onClick={onNavigate}
              value="Frage stellen"
            />
          </div>
        </div>
      </div>
      <Stripes />
    </>
  );
};

export default FixedSizeComponentDisskussion;
