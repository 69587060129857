import React from "react";

export function Switch({ title, name, selectedQuestions, onCheckboxChange }) {
  const id = `${name}-checkbox`;

  return (
    <div className="einzelfilter">
      <span className="filterspan" id={`${id}-label`}>
        {title}
      </span>
      <label className="switch" htmlFor={id}>
        <input
          id={id}
          type="checkbox"
          name={name}
          checked={selectedQuestions[name]}
          onChange={onCheckboxChange}
          aria-labelledby={`${id}-label`}
        />
        <span className="slider round"></span>
      </label>
    </div>
  );
}
