import React from "react";
import { Link } from "react-router-dom";

export function NotFound() {
  return (
    <div className="errorcomponent">
      <div className="box">
        <h1>
          Oh nein... (404) Leider konnten wir die von ihnen gesuchte Seite nicht
          finden.
        </h1>
        <br />
        <p>Zur Startseite gelangst du hier:</p>
        <br />
        <Link className="dropdownerror" to="/">
          Startseite
        </Link>
        <br /> <br />
      </div>
    </div>
  );
}
