export function Fragenlustig() {
  const texts = [
    "mehr als 200€ an einem Tag beim Feiern ausgegeben",
    "einen Fanbrief an einen Prominenten geschrieben",
    "versucht auf TikTok oder Instagram viral zu gehen",
    "einen Geist gesehen",
    "das Haus von jemandem mit Eiern beworfen.(z.B. Halloween)",
    "den Text eines Liedes komplett falsch verstanden und trotzdem lauthals mitgesungen.",
    "aus Versehen jemanden fremden umarmt, weil ich dachte, es wäre ein Freund",
    "beim Fernsehschauen eine Reality-TV-Show ernst genommen.",
    "so getan, als würde ich Sport machen, um zu beeindrucken.",
    "meine eigenen Tanzmoves im Spiegel geübt.",
    "versucht, jemanden zu beeindrucken, und dabei kläglich versagt.",
    "einen Streich gespielt, der völlig außer Kontrolle geriet.",
    "einen Tag nur mit Junk Food verbracht.",
    "versucht, mit einem Akzent zu sprechen und bin kläglich gescheitert.",
    "eine Rede gehalten, die komplett improvisiert war.",
    "so gelacht, dass mir mein Getränk zu Nase kam.",
    "eine Party gecrasht, zu der ich nicht eingeladen war.",
    "die ganze Nacht durchgemacht",
    "in der Dusche gepinkelt",
    "eine ganze Packung Eiscreme alleine gegessen",
    "bei einem Gesellschaftsspiel geschummelt",
    "einen Kosenamen gehabt, für den ich mich geschämt habe",
    "jemandem getroffen, der:die supernervig ist, nur weil ich schlecht Nein sagen kann",
    "bei einem ersten Date gelogen",
    "in Schwester/Bruder eines*er Freundes*in verknallt",
    "eine Überraschungsparty veranstaltet",
    "Schlagsahne direkt aus der Sprühdose gegessen",
    "bei einem*er Hellseher*in",
    "mit einem Familienmitglied über mein Sexleben gesprochen",
    "Haus aus Versehen in Hausschuhen verlassen",
    "mit jemandem nur wegen seines:ihres Aussehens verabredet",
  ];

  return { texts };
}
