export function Fragen18() {
  const texts = [
    "eine vergebene Person geküsst",
    "Analsex gehabt",
    "Nacktbilder verschickt",
    "Drogen genommen",
    "gesagt bekommen, dass ich gut/schlecht im Bett bin",
    "aus Langeweile masturbiert",
    "in einer Unterhaltung an Sex gedacht",
    "einen geblasen bekommen",
    "jemandem einen geblasen",
    "einen Dreier gehabt",
    "einen One-Night-Stand gehabt",
    "so viel getrunken dass ich einen Filmriss hatte",
    "mich für jemanden elegant ausgezogen/gestrippt",
    "mir vorgestellt ich hätte Sex mit einem platonischen Freund/Freundin",
    "beim Sex gelacht oder geweint",
    "ein Nacktbild verschickt",
    "Viagra benutzt",
    "meine:n Ex gestalked",
    "Sexspielzeug benutzt",
    "eine Person hier im Raum geküsst",
    "mit zwei Personen am selben Tag geschlafen",
    "Sex im Auto gehabt",
    "Sex in der Öffentlichkeit gehabt",
    "Sex außerhalb des Hauses gehabt",
    "multiple Orgasmen gehabt",
    "einen Orgasmus vorgetäuscht",
    "Kamasutra ausprobiert",
    "mehr als 3x am Tag masturbiert",
    "Fesselspiele ausprobiert",
    "mich über BDSM informiert",
    "mit meinem Expartner:in wieder was angefangen",
    "Sperma geschmeckt",
    "für Sex bezahlt",
    "Sex ohne Gefühle gehabt",
    "Versöhungssex gehabt",
    "mich beim Sex gefilmt",
    "Sex bereut",
    "ein Dickpick bekommen",
    "mit einer anderen Person geduscht",
    "einen Orgasmus vorgetäuscht",
    "versucht jemanden scharf zu machen in dem ich schmutzige Sache gesagt habe",
    "für Sex Geld oder Geschenke bekommen",
    "mit einem Tinderdate geschlafen",
    "einen Vaterschaftstest gemacht",
    "einen Schwangerschaftstest gemacht",
  ];

  return { texts };
}
