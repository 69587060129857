export function DeeptalkFragen() {
  const texts = [
    "Wenn Du Dich für eine beliebige Person entscheiden könntest, Tod oder Lebendig: Wen hättest Du gern als Tischgast beim Essen?",
    "Wärst Du gern berühmt? In welcher Form?",
    "Wie würde für dich ein perfekter Tag aussehen, wenn du nur Dinge tun dürftest die du schonmal gemacht hast.",
    "test",
    "test",
    "test",
    "test",
  ];

  return { texts };
}
