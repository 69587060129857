import React from "react";

export const Pictureanchor = ({ imageSrc, link, alttext, lable }) => {
  return (
    <a href={link} className="Pictureanchor">
      <img
        src={imageSrc}
        alt={alttext}
        href={link}
        style={{
          width: "200px", // Beispiel-Breite, anpassbar
          height: "200px", // Beispiel-Höhe, anpassbar
          borderRadius: "15px", // Rundung der Ecken
          objectFit: "cover", // Bild wird passend zugeschnitten
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", // Optionaler Schatteneffekt
        }}
      />
      <p className="centertext">{lable}</p>
    </a>
  );
};
export default Pictureanchor;
